/** @jsx jsx */
import {Box,jsx,Grid,Text,Link as ThLink} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {Link} from 'gatsby'
import {graphql} from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'






export default function VierterSalon({data}){
 
return(
  <Layout>
    <Box sx={{maxWidth:1100,height:"100vh",paddingBottom:40}}>
     <h2 sx={{
              fontWeight:300,
              textDecoration: `none`,
              fontSize:[3,5,6],
              color:`text`,
              py:0,
              my:0,
          }} >Salon N° 4 </h2>
          <span style={{fontSize:"3",paddingBottom:20,display:"block"}}>am 26. August 2022 in der Galerie Stadtmühle in St. Goarshausen
          </span>
         
   
     <MySEO
     title="Der vierte Salon von K.0.M in St. Goarshausen"
     
    />

     <Grid columns={[1,2,2]} spacing="5" >
    <Box pt="30" color="text" >
    
     <Text variant="titel" py="20px">
      Von der Sehnsucht anzukommen und der Hoffnung nie da zu sein.
     Mit den Reisenden Karl Baedeker, Alexander von Humboldt, Alexandra David Néel und anderen
     </Text>
     <Text sx={{paddingTop:20}}>1835 „erfand“ Karl Baedeker in seiner Verlagsbuch­handlung am Koblenzer Görresplatz den modernen Reiseführer. Mit dessen charakteristischem roten Einband schuf er eine neue Buchgattung. Seine ebenso niveau­vollen wie detailreichen gedruckten Reisebegleiter besetzten nicht nur eine Marktlücke. Der Baedeker wurde zum Synonym für den Reiseführer schlechthin. Baedekers Reisebeschreibungen sind der rote Faden im Programm. Reiseberichte von Alexander von Humboldt und Alexandra David-Néel berichten auf ganz unterschiedliche Weise von den Abenteuern der beiden großen Reisenden. Ob das Reisen in Zeiten der Pandemie neu erfunden werden muss, bleibt herauszufinden – doch ganz darauf zu verzichten ist nicht nötig: Mit Globus, Reiseliteratur und viel Entdeckerfreude macht sich das musikalisch-literarische Programm von taw – theater am werk auf eine imaginative Reise.

     </Text>
     <Text variant='titel' mt="60px">Die Regisseurin</Text>
<Text pt="2">Walla Heldermann studierte Theaterpädagogik in Köln und Heidelberg, absolvierte die Schauspiel­ausbildung in Bonn, eine Regieausbildung in Moskau, Berlin und Essen sowie diverse Studien in Tanz, Sprache und Gesang. 2001 gründete Walla Heldermann das taw – theater am werk koblenz e.V. und ist dort hauptverantwortlich für Inszenierung, Text- und Musikauswahl, Produktions- und Theaterleitung.
  </Text>
  <Text>
  Freitag, 26. August
  Einlass ab 18 Uhr, Beginn um 19 Uhr
  Eintritt: 15,- Euro
  Galerie Stadtmühle, Dolkstr. 11, 56346 St. Goarshausen
  </Text>
<Text variant='titel'>
Anmeldung

</Text>
<Text pt="2">
Wir freuen uns über Ihre Anmeldung unter: <br/>kontakt@kulturnetz-oberes-mittelrheintal.org <br/>
oder telefonisch bei Evelyn Sattler: 06771.95010</Text>
<Text>
Das Rahmenprogramm startet um 19:00 Uhr und wird gegen 22:00 Uhr ausklingen.</Text><Text><strong>Die letzte Fähre in 
St. Goarshausen fährt um 22:30 Uhr</strong>.<br/>
</Text>
  

     <a href="/pdf/Flyer_Salon4.pdf">Download Flyer</a> <br/> <br/>
     <Link to="/salons"><ThLink> Alle Salons</ThLink></Link>
     </Box>

   
  <Box>
    <Box my="1"><GatsbyImage image={data.th1.childImageSharp.gatsbyImageData} alt="Theateraufführung" />
    <Text fontSize="0">BeatriceHutter, Stephan Rumphorst </Text>
    </Box>
    <Box my="1"><GatsbyImage image={data.th2.childImageSharp.gatsbyImageData} alt="Theateraufführung" />
 
    <Text fontSize="0">BeatriceHutter, Stephan Rumphorst </Text></Box>

    <Box my="1"><GatsbyImage image={data.th3.childImageSharp.gatsbyImageData} alt="Theateraufführung" />
    <Text fontSize="0">Stephan Rumphorst, Beatrice Hutter, Walla Heldermann, Winfried Schuld</Text>
    </Box>



  
  

</Box>
</Grid>

</Box>
    </Layout>
)
}


export const Sa4Gquery = graphql`
  query {
    th1:file(relativePath: { eq: "salons/salon4/1.jpeg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    th2:file(relativePath: { eq: "salons/salon4/2.jpeg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    th3:file(relativePath: { eq: "salons/salon4/3.jpeg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
  }`